import {
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
  DialogActions,
  useTheme
} from "@material-ui/core";
import React from "react";
import ButtonWithSpinner from "../../components/ButtonWithSpinner";
import { i18n } from "../../translate/i18n";

const Modal = ({ modalOpen, onClose, onOK, title, children }) => {
  const theme = useTheme();
  const handleClose = () => {
    onClose();
  };

  const handleOk = () => {
    onOK();
  };
  return (
    <>
      <Dialog open={modalOpen} onClose={handleClose}>
        <DialogTitle id="form-dialog-title">{title}</DialogTitle>
        <DialogContent dividers>{children}</DialogContent>
        <DialogActions>
          <Button
            onClick={handleClose}
            color="gray"
            disabled={false}
            variant="outlined"
          >
            {i18n.t("newTicketModal.buttons.cancel")}
          </Button>
          <ButtonWithSpinner
            variant="contained"
            type="button"
            disabled={false}
            onClick={() => handleOk()}
            color="primary"
            loading={false}
            style={{ color: theme.palette.secondary.contrastText }}
          >
            OK
          </ButtonWithSpinner>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default Modal;
