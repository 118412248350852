import React, { createContext, useState, useContext, useMemo } from "react";
import PropTypes from "prop-types";
import { createMuiTheme, ThemeProvider as MUIThemeProvider } from "@material-ui/core/styles";
import { alpha, CssBaseline, getContrastRatio } from "@material-ui/core";

const ThemeContext = createContext();

export const ThemeProvider = ({ children }) => {
  const [darkMode, setDarkMode] = useState(false);

  const toggleTheme = () => {
    setDarkMode((prevMode) => !prevMode);
  };

  const StorMain = '#008C6E';
  const StorLight = '#24A78AFF';

  const theme = useMemo(
    () =>
      createMuiTheme({
        
          scrollbarStyles: {
            "&::-webkit-scrollbar": {
              width: "8px",
              height: "8px",
            },
            "&::-webkit-scrollbar-thumb": {
              boxShadow: "inset 0 0 6px rgba(0, 0, 0, 0.3)",
              backgroundColor: "#e8e8e8",
            },
          },
          palette: {
            type: darkMode ? "dark" : "light",
            primary: { 
              main: StorMain, 
              light: StorLight,
              dark: "#015342FF",
              contrastText: getContrastRatio(StorMain,'#f5f5f5',) > 4.5 ? '#f5f5f5' : '#161616',
            },
            text: { 
             primary: "#008c6e",
             //secondary: "#4D4D4d",
             light: "#F5F5F5",
             dark: "#161616"
            },
          },
        
        
      }),
    [darkMode]
  );

  const contextValue = useMemo(() => ({ darkMode, toggleTheme }), [darkMode]);

  return (
    <ThemeContext.Provider value={contextValue}>
      <MUIThemeProvider theme={theme}>
        <CssBaseline />
        {children}
      </MUIThemeProvider>
    </ThemeContext.Provider>
  );
};
ThemeProvider.propTypes = {
  children: PropTypes.node.isRequired,
};

export const useThemeContext = () => useContext(ThemeContext);
