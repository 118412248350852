import React from "react";

const Column = ({ children, wAuto, w40, aiCenter, jcCenter }) => {
  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        width: wAuto ? "auto" : w40 ? "40%" : "100%",
        height: "auto",
        alignItems: aiCenter ? "center" : "initial",
        justifyContent: jcCenter ? "center" : "initial"
      }}
    >
      {children}
    </div>
  );
};

export default Column;
