import React from "react";

const Row = ({ children, h100, aiCenter, jcCenter }) => {
  return (
    <div
      style={{
        display: "flex",
        width: "100%",
        height: h100 ? "100%" : "auto",
        alignItems: aiCenter ? "center" : "initial",
        justifyContent: jcCenter ? "center" : "initial"
      }}
    >
      {children}
    </div>
  );
};

export default Row;
